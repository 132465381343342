.blogpost-container {
    width: 700px;
    align-items: center;
    text-align: center;
    display: flex;
    flex-flow: column;
    margin: 0 auto;
    margin-top: 30px;
    margin-bottom: 30px;
    border-radius: 10px;
    box-shadow: 0 6px 20px rgba(20, 42, 82, 0.17);
    -webkit-filter: drop-shadow(0 6px 20px rgba(16, 31, 60, 0.017));
    filter: drop-shadow(0 6px 20px rgba(13, 26, 51, 0.017));
    background: rgb(37, 47, 55);
    color: white;
}

.blogposthero-col {
    display: flex;
    flex-flow: column;
    width: 100%;
    text-align: left;
    padding: 20px;
}

.blogposthero-row-header {
    display: flex;
    flex-flow: row;
    padding-left: 20px;
    padding-right: 20px;
    align-self: center;
}

.blogpost-header-text {
    font-size: 20px;
}

.blogpost-content-text {
    font-size: 15px;
    font-weight: bold;
}

.blogposthero-content {
    font-size: 15px;
    padding-left: 5px;
    padding-right: 5px;
}

.red-input-underline {
    color: red;
    text-decoration: underline;
}

.blogpost-links {
    color: rgb(98, 98, 225) !important;
}

.blogpost-links a:link {
    color: rgb(98, 98, 225);   
}

.blogpost-links a:hover {
    text-decoration: underline;
    color: rgb(98, 98, 225) !important;
}

@media only screen and (max-width: 480px) {
    .blogpost-container {
        max-width: 100%;
        align-items: center;
        text-align: center;
        display: flex;
        flex-flow: column;
        margin: 0 auto;
        margin-top: 30px;
        margin-bottom: 30px;
        margin-right: 5px;
        border-radius: 10px;
        box-shadow: 0 6px 20px rgba(20, 42, 82, 0.17);
        -webkit-filter: drop-shadow(0 6px 20px rgba(16, 31, 60, 0.017));
        filter: drop-shadow(0 6px 20px rgba(13, 26, 51, 0.017));
        background: rgb(37, 47, 55);
        color: white;
    }

    .blogposthero-col {
        display: flex;
        flex-flow: column;
        width: 100%;
        text-align: left;
        padding-top: 20px;
        padding-bottom: 20px;
        padding-left: 0px;
        padding-right: 0px;
        word-wrap: anywhere;
    }

    .blogposthero-row-header {
        display: flex;
        flex-flow: row;
        align-self: center;
    }

    .blogpost-links a:link {
        color: rgb(98, 98, 225);
    }
    
    .blogpost-links a:hover {
        text-decoration: underline;
        color: rgb(98, 98, 225) !important;
    }
}
