.navbar-container {
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-flow: row;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    background: rgb(24, 25, 29);
    border-radius: 10px;
}

.navbar-container-inner {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-flow: row;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: rgb(28, 175, 187);
    color: black;
    font-weight: bold;
    border-radius: 10px;
}

.icon-container {
    width: 25%;
    display: flex;
    flex-flow: column;
    align-items: center;
}

.icon-container:hover {
    scale: 1.1;
}

.icon-text {
    width: 25%;
    font-size: 15px;
}

.navbar-icon {
    color: white;
}

a:link {
    text-decoration: none;
    color: white;
}

a:visited {
    text-decoration: none;
    color: white;
}

a:hover {
    text-decoration: none;
    color: white !important;
}

a:active {
    text-decoration: none;
    color: white !important;
}

@media only screen and (max-width: 480px) {
}