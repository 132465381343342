.login-gamerules {
    color: blue;
    margin-left: 140px;
}

.login-gamerules:hover {
    color: blue !important;
    margin-left: 140px;
}

.login-gamerules-input {
    height: 40px;
    border-radius: 4px;
    border: solid 1px;
}

.authenticatorCSS {
    margin-top: 50px;
    margin-bottom: 50px;
}

a:hover {
    color: blue;
}

:root {
    /*
    --amplify-colors-orange: hsla(0, 0%, 0%, 0.889);
    --amplify-colors-blue: hsl(0, 0%, 100%);
    --amplify-colors-brand-primary-10: var(--amplify-colors-teal-100);
    --amplify-colors-brand-primary-80: var(--amplify-colors-teal-40);
    --amplify-colors-brand-primary-90: var(--amplify-colors-teal-20);
    --amplify-colors-brand-primary-100: var(--amplify-colors-teal-10);
    --amplify-colors-background-primary: var(--amplify-colors-orange);
    --amplify-colors-background-secondary: var(--amplify-colors-orange);
    --amplify-components-field-label-color:var(--amplify-colors-blue);
    
    --amplify-components-fieldcontrol-color:var(--amplify-colors-blue);
    --amplify-colors-border-primary:var(--amplify-colors-orange);
    --amplify-colors-border-secondary: var(--amplify-colors-orange);
    */
}

@media only screen and (max-width: 480px) {
    .login-gamerules {
        color: blue;
        margin-left: 20px !important;
    }

    .homeBanner-lightboxImage {
        height: 50%;
        width: 100%;
        cursor: zoom-out;
    }
      
  }