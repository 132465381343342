.game-container {
    width: 700px;
    align-items: center;
    text-align: center;
    display: flex;
    flex-flow: column;
    margin: 0 auto;
    margin-bottom: 50px;
    margin-top: 30px;
    border-radius: 10px;
    box-shadow: 0 6px 20px rgba(20, 42, 82, 0.17);
    -webkit-filter: drop-shadow(0 6px 20px rgba(16, 31, 60, 0.017));
    filter: drop-shadow(0 6px 20px rgba(13, 26, 51, 0.017));
}

.game-container-back {
    width: 700px;
    align-items: left;
    text-align: left;
    display: flex;
    flex-flow: column;
    margin: 0 auto;
    margin-bottom: 50px;
    margin-top: 30px;
    border-radius: 10px;
    box-shadow: 0 6px 20px rgba(20, 42, 82, 0.17);
    -webkit-filter: drop-shadow(0 6px 20px rgba(16, 31, 60, 0.017));
    filter: drop-shadow(0 6px 20px rgba(13, 26, 51, 0.017));
}

.gamehero-container {
    width: 100%;
    /* background: rgb(86,172,194); */
    background: rgb(37, 47, 55);
    padding: 30px;
    border-radius: 10px;
    word-wrap: break-word;
    color: white;
    display: flex;
    flex-flow: column;
}

.gamehero-container-your-history {
    margin-left: 10px;
    align-self: flex-start;
}

.game-play-input {
    border: none;
    height: 35px;
    border-radius: 10px;
}

.game-play-button {
    background-color: rgb(28, 175, 187);
    color: white;
    border: none;
    height: 35px;
    border-radius: 10px;
    margin-right: 140px;
}

.game-play-button-cont-left {
    background-color: rgb(28, 175, 187);
    color: white;
    border: none;
    height: 35px;
    border-radius: 10px;
    margin-left: 140px;
    width: 50%;
}

.game-play-button-cont-left-chosen {
    background-color: rgb(23, 78, 83);
    color: white;
    border: none;
    height: 35px;
    border-radius: 10px;
    margin-left: 140px;
    width: 50%;
}

.game-play-button-cont-right {
    background-color: rgb(28, 175, 187);
    color: white;
    border: none;
    height: 35px;
    border-radius: 10px;
    margin-right: 140px;
    width: 50%;
}

.game-play-button-cont-right-chosen {
    background-color: rgb(23, 78, 83);
    color: white;
    border: none;
    height: 35px;
    border-radius: 10px;
    margin-right: 140px;
    width: 50%;
}

.insurance-claim-button {
    align-self: center;
    width: 35%;
    background-color: rgb(28, 175, 187);
    color: white;
    border: none;
    border-radius: 5px;
    height: 35px;
}

.gamepage-gamepoint {
    font-weight: bold;
    align-self: center;
    padding: 10px 25px 10px 25px;
     background-color: rgb(255, 255, 255);
    /*background: linear-gradient(270deg, rgb(155, 196, 214) 0%, rgb(145, 154, 175) 100%);*/
    border-radius: 14px;
    margin-left: 250px;
    color: black ;
    opacity: 85%;
}

.gamehero-container h4 {
    align-items: left;
    align-self: left;
    align-content: left;
    left: 0;
}

#bethistory-table {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    max-width: 100%;
    font-size: 14px;
    border: solid 1px 0px 1px 0px black;
    table-layout:fixed;
}

#bethistory-table td, #bethistory-table th {
    padding: 8px;
    text-align: left;
    background-color: rgb(30, 38, 42);
}

#bethistory-table th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: rgb(19, 24, 28);
    color: white;
    border: solid 1px black;
}


.game-container-inner {
    max-width: 700px;
    margin: 0 auto;
    margin-top: 30px;
    -webkit-filter: drop-shadow(0 6px 20px rgba(16, 31, 60, 0.017));
}

.gamehero-container-inner {
    width: 100%;
    padding: 30px;
    border-radius: 10px;
    word-wrap: break-word;
}

.close-button {
    
    background-color: #fff;
}

.gamehero-floatleft {
    width: 50%;
}

.gamehero-floatleft-cont {
    width: 100%;
}

.gamehero-containerrow input {
    margin-top: 30px;
    margin-left: 140px;
    width: 50%;
}

.gamehero-containerrow-cont input {
    text-align: center;
}

.gamehero-containerrow button {
    margin-top: 30px;
    width: 50%;
}

.gamehero-containerrow-cont button {
    margin-top: 30px;
    margin-right: 140px;
    width: 50%;
}

.gamehero-containerrow-cont-button {
    display: flex;
    flex-flow: row;
    width: 100%;
    margin-top: 30px;
    font-size: 15px;
}

.gamehero-floatmid {
    width: 100%
}

.gamehero-floatmid p {
    margin-top: 20px;
}

.gamehero-floatmid:hover {
    scale: 1.1;
}

.gamehero-floatright {
    width: 50%;
}

.gamehero-containerrow {
    display: flex;
    flex-flow: row;
    width: 100%;
}

.gamehero-containercol {
    display: flex;
    flex-flow: column;
}

.gamehero-containercol h3 {
    align-self: center;
}

.hashResults {
    color: rgb(176, 172, 172);
}

.hashLastTwo {
    color: white;
}

.gamehero-containercol span {
    margin-top: 20px;
}

.gamelogo {
    width: 80px;
    height: 80px;
}

.gamelogo-small {
    width: 50px;
    height: 50px;
}

.gametype-title {
    padding-left: 15px;
    align-self: center;
}

.wonBetMessage {
    margin-top: 20px;
}

@media only screen and (max-width: 480px) {
    .game-container {
        width: 100%;
        background: #fff;
        align-items: center;
        text-align: center;
        display: flex;
        flex-flow: column;
        margin: 0 auto;
        margin-top: 30px;
        margin-bottom: 30px;
        border-radius: 10px;
        box-shadow: 0 6px 20px rgba(20, 42, 82, 0.17);
        -webkit-filter: drop-shadow(0 6px 20px rgba(16, 31, 60, 0.017));
        filter: drop-shadow(0 6px 20px rgba(13, 26, 51, 0.017));
    }

    .game-container-back {
        width: 100%;
        align-items: left;
        text-align: left;
        display: flex;
        flex-flow: column;
        margin: 0 auto;
        border-radius: 10px;
        box-shadow: 0 6px 20px rgba(20, 42, 82, 0.17);
        -webkit-filter: drop-shadow(0 6px 20px rgba(16, 31, 60, 0.017));
        filter: drop-shadow(0 6px 20px rgba(13, 26, 51, 0.017));
    }
    
    .gamehero-container {
        width: 100%;
        /* background: rgb(86,172,194); */
        background: rgb(37, 47, 55);
        padding-top: 30px;
        padding-bottom: 30px;
        padding-left: 5px;
        padding-right: 0px;
        border-radius: 10px;
        word-wrap: break-word;
        color: white;
        display: flex;
        flex-flow: column;
    }

    .game-container-inner {
        max-width: 100%;
        margin: 0 auto;
        margin-top: 30px;
        -webkit-filter: drop-shadow(0 6px 20px rgba(16, 31, 60, 0.017));
    }

    .gamehero-container-inner {
        width: 100%;
        padding-left: 30px;
        padding-top: 0px;
        padding-bottom: 0px;
        border-radius: 10px;
        word-wrap: break-word;
    }
    .gamehero-container-back {
        width: 100%;
        padding-left: 0px;
        padding-top: 0px;
        padding-bottom: 0px;
        border-radius: 10px;
        word-wrap: break-word;
    }

    .close-button {
        background-color: #fff;
    }

    .gamepage-gamepoint {
        max-width: 150px;
        font-weight: bold;
        align-self: center;
        padding: 10px 25px 10px 25px;
        background-color: rgb(255, 255, 255);
        border-radius: 14px;
        color: black ;
        opacity: 85%;
        position: absolute;
        right:0;
        margin-right: 5px;
    }

    .gamehero-containerrow input {
        margin-top: 30px;
        margin-left: 55px;
        width: 60%;
    }
    
    .gamehero-containerrow button {
        margin-top: 30px;
        margin-right: 55px;
        width: 70%;
        font-size: 15px;
    }
    
    .gamehero-containerrow-cont-button {
        display: flex;
        flex-flow: row;
        width: 100%;
        margin-top: 30px;
        width: 100%;
        font-size: 15px;
    }

    #bethistory-table {
        font-family: Arial, Helvetica, sans-serif;
        border-collapse: collapse;
        max-width: 100%;
        font-size: 14px;
        border: solid 1px 0px 1px 0px black;
        table-layout:fixed;
        margin-right: 5px;
    }
    
    #bethistory-table td, #bethistory-table th {
        padding: 8px;
        text-align: left;
        background-color: rgb(30, 38, 42);
    }
    
    #bethistory-table th {
        padding-top: 12px;
        padding-bottom: 12px;
        text-align: left;
        background-color: rgb(19, 24, 28);
        color: white;
        border: solid 1px black;
    }

    .game-play-button-cont-left {
        background-color: rgb(28, 175, 187);
        color: white;
        border: none;
        height: 35px;
        border-radius: 10px;
        margin-left: 55px;
        width: 60%;
    }
    
    .game-play-button-cont-right {
        background-color: rgb(28, 175, 187);
        color: white;
        border: none;
        height: 35px;
        border-radius: 10px;
        margin-right: 55px;
        width: 60%;
    }

    .game-play-button-cont-left-chosen {
        background-color: rgb(23, 78, 83);
        color: white;
        border: none;
        height: 35px;
        border-radius: 10px;
        margin-left: 55px;
        width: 60%;
    }
    
    .game-play-button-cont-right-chosen {
        background-color: rgb(23, 78, 83);
        color: white;
        border: none;
        height: 35px;
        border-radius: 10px;
        margin-right: 55px;
        width: 60%;
    }

}