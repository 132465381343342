.header {
    /*background: linear-gradient(90deg, rgb(72, 47, 118) 0%, rgb(90, 191, 206) 100%);*/
    background: black;
    height: 80px;
    display: flex;
    font-size: 1.2rem;
    position: sticky;
    z-index: 1;
}

.header-container {
    display: flex;
    flex-flow: row;
    height: 80px;
    max-width: 700px;
    align-items: center;
}

.header-spacer {
    width: 240px;
}

.header-cs-a {
    margin-right: 15px;
}

.header-cs-b {
    font-size: 40px;
}

.header-logo {
    color: #fff !important;
    cursor: pointer;
    width: 420px;
}

.header-logo-img {
    max-height: 70%;
    max-width: 70%;
}

@media only screen and (max-width: 480px) {
    .header {
        /*background: linear-gradient(90deg, rgb(72, 47, 118) 0%, rgb(90, 191, 206) 100%);*/
        background: black;
        height: 65px;
        display: flex;
        font-size: 1.2rem;
        position: sticky;
        z-index: 1;
    }
    
    .header-container {
        display: flex;
        flex-flow: row;
        max-height: 100%;
        max-width: 100%;
        align-items: center;
    }
      
    .header-logo {
        color: #fff !important;
        cursor: pointer;
        width: 100%;
    }

    .header-logo-img {
        max-height: 60%;
        max-width: 60%;
    }


.header-cs-a {
    margin-right: 15px;
}

.header-cs-b {
    font-size: 28px;
}

.header-spacer {
    width: 20px;
}

}