.profile-container {
    width: 700px;
    align-items: center;
    text-align: center;
    display: flex;
    flex-flow: column;
    margin: 0 auto;
    margin-top: 30px;
    margin-bottom: 30px;
    border-radius: 10px;
    box-shadow: 0 6px 20px rgba(20, 42, 82, 0.17);
    -webkit-filter: drop-shadow(0 6px 20px rgba(16, 31, 60, 0.017));
    filter: drop-shadow(0 6px 20px rgba(13, 26, 51, 0.017));
    font-size: 15px;
    background: rgb(37, 47, 55);
    color: white;
}

.navbar-container {
    width: 100%;
}

.profilehero-container {
    width: 100%;
}

.copytoclipboardbutton {
    margin-left: 20px;
}

.profile-icon {
    color: black;
    margin-right: 10px;
}

.profilehero-row {
    display: flex;
    flex-flow: row;
    width: 100%;
    padding-top: 30px;
}

.profilehero-row-downline {
    display: flex;
    flex-flow: row;
    width: 100%;
}

.profilehero-col {
    display: flex;
    flex-flow: column;
    width: 100%;
    padding-top: 30px;
}

.profile-container-col {
    display: flex;
    flex-flow: column;
    width: 100%;
    text-align: left;
    padding: 20px;
    padding-bottom: 0px;
}

.profile-contact {
    color: black !important;
}

.profile-contact-link {
    color: white !important;
}

.profile-ref-input {
    background-color: rgb(22, 28, 33);
    color: white;
    border: none;
}

.passwordchange-container {
    display: flex;
    flex-flow: column;
    width: 100%;
    text-align: left;
    padding: 20px;
}

.downline-ref-input {
    background-color: rgb(22, 28, 33);
    color: white;
    border: none;
    width: 70%;   
}

.passwordchange-container h3 {
    margin-top: 0;
    align-self: center;
}

.passwordchange-container input {
    margin-bottom: 20px;
}

.passwordchange-form {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);

    display: flex;
    justify-content: center;
    align-items: center;
}

.passwordchange-forminner {
    position: relative;
    padding: 32px;
    width: 450px;
    max-width: 640px;
    background-color: #fff;
    border-radius: 15px;
}

.passwordchange-forminner .close-button {
    position: absolute;
    top: 15px;
    left: 20px;
    background-color: #fff;
}

.passwordchange-button {
    border: none;
    background: rgb(37, 47, 55);
    color: white;
    padding: 0;
    align-self: start;
}

.passwordchange-input {
    height: 40px;
    border-radius: 5px;
    border: none;
}

.passwordchange-submitbutton {
    border: none;
    background-color: #fff;
    padding: 0;
    width: 30%;
    height: 35px;
    align-self: center;
    background-color: rgb(102, 194, 58);
    border-radius: 10px;
}

.commissionearning-h3 {
    font-size: 20px;
}

#downline-table {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 40%;
    font-size: 14px;
    border: solid 1px 0px 1px 0px black;
    align-self: center;
    margin-top: 10px;
}

#downline-table td, #downline-table th {
    padding: 8px;
    background-color: rgb(30, 38, 42);
    color: white !important;
}

#downline-table th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: rgb(19, 24, 28);
}

.signout-button {
    border: none;
    background: rgb(37, 47, 55);
    color: white;
    padding: 0;
}

.profilekey {
    width: 35%;
    text-align: left;
    padding-left: 20px;;
}

.profilevalue {
    width: 65%;
    text-align: left;
}

.profilevalue-downline {
    width: 100%;
    text-align: left;
}

.divider {
    width: 90%;
    margin-top: 0;
    margin-bottom: 0;
}

.divider-full {
    width: 100%;
}

@media only screen and (max-width: 480px) {
    .profile-container {
        width: 100%;
        align-items: center;
        text-align: center;
        display: flex;
        flex-flow: column;
        margin: 0 auto;
        margin-top: 30px;
        margin-bottom: 30px;
        border-radius: 10px;
        box-shadow: 0 6px 20px rgba(20, 42, 82, 0.17);
        -webkit-filter: drop-shadow(0 6px 20px rgba(16, 31, 60, 0.017));
        filter: drop-shadow(0 6px 20px rgba(13, 26, 51, 0.017));
        font-size: 15px;
        background: rgb(37, 47, 55);
        color: white;
    }

    .downline-ref-input {
        background-color: rgb(22, 28, 33);
        color: white;
        border: none;
        width: 100%;   
    }

    .copytoclipboardbutton {
        margin-left: 5px;
    }
    
    .profile-container-col {
        display: flex;
        flex-flow: column;
        width: 100%;
        text-align: left;
        padding-top: 20px;
        padding-bottom: 0px;
        padding-left: 7px;
        padding-right: 7px;
    }

    .commissionearning-h3 {
        font-size: 18px;
    }
}