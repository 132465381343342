.progress {
    height: 10rem !important;
}

.progress-bar {
    color: black !important;
}

.mainDiv {
    background: rgb(86,172,194);
}