.assets-container {
    width: 700px;
    align-items: center;
    text-align: center;
    display: flex;
    flex-flow: column;
    margin: 0 auto;
    margin-top: 30px;
    margin-bottom: 30px;
    border-radius: 10px;
    box-shadow: 0 6px 20px rgba(20, 42, 82, 0.17);
    -webkit-filter: drop-shadow(0 6px 20px rgba(16, 31, 60, 0.017));
    filter: drop-shadow(0 6px 20px rgba(13, 26, 51, 0.017));
    background: rgb(37, 47, 55);
    color: white;
}

.navbar-container {
    width: 100%;
    color: white;
    font-weight: bold;
}

.assetshero-container {
    width: 100%;
    font-size: 15px;
}

.assetshero-row {
    display: flex;
    flex-flow: row;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
}

.assetshero-col {
    display: flex;
    flex-flow: column;
    width: 100%;
    text-align: left;
    padding: 20px;
}

.assets-floatleft {
    width: 50%;
    right: 0;
    align-self: center;
}

.assets-floatright {
    width: 50%;
    margin-left: 120px;
    align-self: center;
}

.assetshero-containerrow {
    display: flex;
    flex-flow: row;
    width: 100%;
}

.assetshero-containerrow select {
    margin-bottom: 30px;
    margin-left: 160px;
    width: 50%;
}

.assetshero-containerrow button {
    margin-bottom: 30px;
    margin-left: 10px;
    width: 50%;
}

.assetshero-floatleft {
    width: 50%;
}

.assetshero-floatright {
    width: 50%;
}

.deposite-stripe-input {
    border: none;
    height: 35px;
    border-radius: 10px;
}

.deposite-stripe-button {
    background-color: rgb(28, 175, 187);
    color: white;
    border: none;
    height: 35px;
    border-radius: 10px;
    margin-right: 140px;
}

.assets-gamepoint-input-text {
    font-size: 20px;
}

.assets-gamepoint-input {
    font-size: 25px;
}

.assetshero-withdraw-button {
    background-color: rgb(102, 194, 58);
    width: 100px;
    border: 0;
    border-radius: 30px;
    margin: 10px;
    height: 50px;
}

.assetshero-deposit-button {
    background-color: rgb(230, 162, 61);
    width: 100px;
    border: 0;
    border-radius: 30px;
    margin: 10px;
    height: 50px;
}

.button-text {
    font-size: 15px;
}

.divider {
    width: 90%;
}

.navbar-icon-assets {
    color: black;
    margin-right: 10px;
}

.redinput {
    color: red;
}

.withdraw-input-text-label {
    width: 95%;
    align-self: center;
    height: 40px;
    margin-bottom: 0px;
    border-radius: 5px;
    border: none;
}

.withdraw-input-text {
    width: 95%;
    align-self: center;
    height: 40px;
    margin-bottom: 30px;
    border-radius: 5px;
    border: none;
}

.withdraw-input-button {
    width: 95%;
    align-self: center;
    height: 40px;
    margin-bottom: 30px;
    border-radius: 5px;
    border: none;
    background-color: rgb(56, 202, 56);
    color: white;
}

.withdraw-p-walletaddress {
    font-size: 22px;
    font-weight: bold;
    align-self: center;
}

.withdraw-p-creditcard {
    font-size: 19px;
    font-weight: bold;
    align-self: center;
}

.withdraw-p {
    font-size: 16px;
    font-weight: bold;
}

.toastMessage {
    margin-top: 350px;
}

.toastMessage-withdraw {
    margin-top: 380px;
}

.withdraw-p-s {
    font-size: 15px;
    align-self: center;
}

.withdraw-link {
    color: red !important;
    text-decoration: underline !important;
}

.divider-white {
    background-color: white;
    height: 2px;
}

.withdraw-link:hover {
    color: red !important;
    text-decoration: underline !important;
}

.deposit-text {
    font-size: 22px;
}

#deposit-table {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
    border: solid 1px 0px 1px 0px black;
    color: white;
}

#deposit-table td, #deposit-table th {
    padding: 8px;
    background-color: rgb(30, 38, 42);
}

#deposit-table th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: rgb(19, 24, 28) !important;
    color: white;
    border: solid 1px black;
}

#withdraw-table {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
    word-wrap: break-word !important;
    overflow-wrap: break-word !important;
    border: solid 1px 0px 1px 0px black;
    color: white;
}

#withdraw-table td, #withdraw-table th {
    padding: 8px;
    word-wrap: break-word !important;
    overflow-wrap: break-word !important;
    background-color: rgb(30, 38, 42);
}

#withdraw-table th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: rgb(19, 24, 28) !important;
    color: white;
    border: solid 1px black;
    word-wrap: break-word !important;
    overflow-wrap: break-word !important;
}

.separator{
    display: flex;
    align-items: center;
}

.separator p {
    padding: 0 2rem; /* creates the space */
    margin-top: 15px;
}

.separator .line{
    flex: 1;
    height: 1px;
    background-color: white;
    opacity: .25;
}

@media only screen and (max-width: 480px) {
    .assets-container {
        max-width: 100%;
        align-items: center;
        text-align: center;
        display: flex;
        flex-flow: column;
        margin: 0 auto;
        margin-top: 30px;
        margin-bottom: 30px;
        margin-right: 5px;
        border-radius: 10px;
        box-shadow: 0 6px 20px rgba(20, 42, 82, 0.17);
        -webkit-filter: drop-shadow(0 6px 20px rgba(16, 31, 60, 0.017));
        filter: drop-shadow(0 6px 20px rgba(13, 26, 51, 0.017));
        background: rgb(37, 47, 55);
        color: white;
    }

    .assets-floatleft {
        width: 35%;
        right: 0;
        align-self: center;
    }

    .assets-floatright {
        width: 65%;
        margin-left: 0px;
        align-self: center;
    }

    .assetshero-row {
        display: flex;
        flex-flow: row;
        width: 100%;
    }

    .assetshero-deposit-button {
        background-color: rgb(230, 162, 61);
        width: 80px;
        border: 0;
        border-radius: 30px;
        margin: 10px;
        height: 40px;
    }

    .assetshero-withdraw-button {
        background-color: rgb(102, 194, 58);
        width: 80px;
        border: 0;
        border-radius: 30px;
        margin: 10px;
        height: 40px;
    }

    .button-text {
        font-size: 12px;
    }

    .assetshero-col {
        display: flex;
        flex-flow: column;
        width: 100%;
        text-align: left;
        padding-top: 20px;
        padding-bottom: 20px;
        padding-left: 0px;
        padding-right: 0px;
        word-wrap: anywhere;
    }

    .withdraw-p {
        font-size: 16px;
        font-weight: 400;
    }

    .withdraw-p-walletaddress {
        font-size: 15px;
        font-weight: bold;
        align-self: center;
    }

    .toastMessage {
        margin-top: 370px !important;
    }

    .toastMessage-withdraw {
        margin-top: 370px !important;
    }

    .withdraw-p-creditcard {
        font-size: 14px;
        font-weight: bold;
        align-self: center;
    }

    .assetshero-containerrow select {
        margin-bottom: 30px;
        margin-left: 60px !important;
        width: 60%;
    }
    
    .assetshero-containerrow button {
        margin-bottom: 30px;
        margin-left: 5px !important;
        width: 60%;
    }

    .deposite-stripe-button {
        background-color: rgb(28, 175, 187);
        color: white;
        border: none;
        height: 35px;
        border-radius: 10px;
        margin-right: 60px;
    }
}

@media only screen and (min-width: 370px) {
    .assetshero-deposit-button {
        background-color: rgb(230, 162, 61);
        width: 80px;
        border: 0;
        border-radius: 30px;
        margin: 10px;
        height: 40px;
    }

    .assetshero-withdraw-button {
        background-color: rgb(102, 194, 58);
        width: 90px;
        border: 0;
        border-radius: 30px;
        margin: 10px;
        height: 40px;
    }
    
}