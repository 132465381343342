.adminhero-container {
    background-color: white;
    width: 100%;
    min-height: 1000px;
    color: black;
    display: flex;
    flex-flow: row;
    margin: 0 auto;
}

.adminhero-container-left {
    width: 50%;
    display: flex;
    flex-flow: column;
    margin: 0 auto;
    border-right: solid 1px;
}

.adminhero-container-left p {
    align-self: center;
    font-weight: bold;
}

.adminhero-container-right p {
    align-self: center;
    font-weight: bold;
}

.adminhero-container-right {
    width: 50%;
    display: flex;
    flex-flow: column;
    margin: 0 auto;
}

.adminhero-container-row {
    width: 50%;
    display: flex;
    flex-flow: row;
    margin: 0 auto;
}

.adminhero-loadbuttons {
    margin: 20px;
    align-self: center;
}

.admin-topup-text {
    width: 100px;
}

.admin-header {
    background-color: white;
}

.admin-loadusers-button {
    width: 150px;
    align-self: center;
}

#admin-table {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
    border: solid 1px 0px 1px 0px black;
    color: white;
}

#admin-table td, #admin-table th {
    padding: 8px;
    background-color: rgb(30, 38, 42);
}

#admin-table th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: rgb(19, 24, 28) !important;
    color: white;
    border: solid 1px black;
}

#deposit-table {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
    /* background-color: white; */
}

#deposit-table td, #deposit-table th {
    padding: 8px;
}

#deposit-table th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: black;
    color: white;
}

#withdraw-table {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
    /* background-color: white; */
}

#withdraw-table td, #withdraw-table th {
    padding: 8px;
}

#withdraw-table th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: black;
    color: white;
}

@media screen and (max-width: 480px) {
    .adminhero-container {
        background-color: white;
        width: 100%;
        color: black;
        display: flex;
        flex-flow: column;
        margin: 0 auto;
    }

    .adminhero-container-left {
        width: 100%;
        display: flex;
        flex-flow: column;
        border-right: none;
        border-bottom: solid 10px;
        margin: 0 auto;
    }

    .adminhero-container-right {
        width: 100%;
        display: flex;
        flex-flow: column;
        margin: 0 auto;
    }
}