.home-container {
    max-width: 700px;
    align-items: center;
    text-align: center;
    display: flex;
    flex-flow: column;
    margin: 0 auto;
    margin-top: 0px;
    border-radius: 10px;
    box-shadow: 0 6px 20px rgba(20, 42, 82, 0.17);
    -webkit-filter: drop-shadow(0 6px 20px rgba(16, 31, 60, 0.017));
    filter: drop-shadow(0 6px 20px rgba(13, 26, 51, 0.017));
    margin-bottom: 50px;
}

.navbar-container {
  max-width: 100%;
}

.homehero-container {
  max-width: 100%;
}

.homehero-container p {
    background-color: rgb(33, 32, 33);
    color: white;
    font-weight: bold;
    margin-bottom: -10px;
    padding-bottom: 25px;
    padding-top: 15px;
    border-radius: 20px 20px 0px 0px;
    z-index: 100;
}

.homeBanner {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
}

.homeBanner-gamerules {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
    cursor: zoom-in;
}

.homeBanner-lightbox {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);

    display: flex;
    justify-content: center;
    align-items: center;
}

.homeBanner-lightbox-betresult {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgb(0, 0, 0, 0.8);

  display: flex;
  justify-content: center;
  align-items: center;
}

.slideshow {
    margin: 0 auto;
    margin-top: 40px;
    margin-bottom: 50px;
    overflow: hidden;
    max-width: 700px;
    max-height: 275px;
    border-radius: 20px 20px 20px 20px;
  }

.slideshow-image {
  max-height: 275px;
  width: 700px;
}

.slideshowSlider {
  white-space: nowrap;
  transition: ease 1000ms;
}

.slide {
  display: inline-block;
  height: 400px;
  width: 100%;
  border-radius: 40px;
}
  
/* Buttons */
.slideshowDots {
  text-align: center;
}

.slideshowDot {
  display: inline-block;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  
  cursor: pointer;
  margin: 15px 7px 0px;
  background-color: #c4c4c4;
}

.slideshowDot.active {
  background-color: #6a0dad;
}

@media only screen and (max-width: 480px) {
  .home-container {
    max-width: 100%;
    background: #fff;
    align-items: center;
    text-align: center;
    display: flex;
    flex-flow: column;
    margin: 0 auto;
    margin-top: 30px;
    border-radius: 10px;
    box-shadow: 0 6px 20px rgba(20, 42, 82, 0.17);
    -webkit-filter: drop-shadow(0 6px 20px rgba(16, 31, 60, 0.017));
    filter: drop-shadow(0 6px 20px rgba(13, 26, 51, 0.017));
    margin-bottom: 50px;
    /* margin-left: 5px; */
    /* margin-right: 5px; */
    }

    .slideshow-image {
      max-height: 275px;
      max-width: 100%;
      /* padding-left: 5px; */
      /* padding-right: 5px; */
    }
    
}