.announcement-container {
    width: 500px;
    align-items: center;
    text-align: center;
    display: flex;
    flex-flow: column;
    margin: 0 auto;
    margin-top: 30px;
    margin-bottom: 30px;
    border-radius: 10px;
    box-shadow: 0 6px 20px rgba(20, 42, 82, 0.17);
    -webkit-filter: drop-shadow(0 6px 20px rgba(16, 31, 60, 0.017));
    filter: drop-shadow(0 6px 20px rgba(13, 26, 51, 0.017));
}

.navbar-container {
    width: 100%;
}

.announcementhero-container {
    width: 100%;
}

@media only screen and (max-width: 480px) {
    .announcement-container {
        width: 100%;
        background: #fff;
        align-items: center;
        text-align: center;
        display: flex;
        flex-flow: column;
        margin: 0 auto;
        margin-top: 30px;
        border-radius: 10px;
        box-shadow: 0 6px 20px rgba(20, 42, 82, 0.17);
        -webkit-filter: drop-shadow(0 6px 20px rgba(16, 31, 60, 0.017));
        filter: drop-shadow(0 6px 20px rgba(13, 26, 51, 0.017));
    }
}